import Http from '../http';

export function apiBasicInfo() {
	return Http('/index/basis/index');
}

export function apiLogin(url,params) {
	return Http(url+'/index/api/login', {
		method: 'POST',
		params: params,
	});
}
